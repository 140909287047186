import { AppConfig } from '@linkx/shared-utils'

export const environment: AppConfig = {
    isProduction: true,
    api: {
        url: '/api',
        version: 'v1'
    },
    awsCognito: {
        domain: 'linkx-int.auth.eu-west-1.amazoncognito.com',
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_7IorYiHYh',
        userPoolWebClientId: '4p73opph8hj6rv1eemff6h0n2j'
    }
}
